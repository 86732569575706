import { Component, HostListener } from '@angular/core';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

@Component({
  selector: 'app-project-watch-list',
  templateUrl: './project-watch-list.component.html',
  styleUrls: ['./project-watch-list.component.scss'],
})
export class ProjectWatchListComponent {
  watchlistData: any
  activeVideo: string | null = null;
  hasTriggered = false;
  pagenationData: any = {
    limit: 10,
    offset: 1,
  };
  constructor(private apihelper: ApiHelper) {}

  ngOnInit() {
    this.getWatchList( this.pagenationData);

    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    // Check if we've reached the bottom of the page (200px from the bottom)
    if (
      window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 200
    ) {
      if (!this.hasTriggered) {  
        this.hasTriggered = true;
        this.getWatchList(this.pagenationData.offset); 
  
        // Simulate adding content and reset after a delay
        setTimeout(() => {
          this.hasTriggered = false;  
        }, 500);  
      }
    }
  }
  getWatchList( pagenation: any = this.pagenationData) {
    this.apihelper
      .post({pagenation}, ApiEndPoints.watchListFind)
      .subscribe((response) => {
        this.watchlistData = response.data.rows
        this.pagenationData.offset++
        console.log('offset' , this.pagenationData.offset)
      });
  }
  onPlayerReady() {}
  onStateChange(event: any) {
    this.activeVideo = event;
  }
  trackById(index: number, item: any): number {
    return item.id; 
  }
}
