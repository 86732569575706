<div class="pad-t container">
  <div class="d-flex justify-content-end pb-2 pe-3">
    <button class="btn btn-grad-ff" [routerLink]="['/view/watchCreate']">
      Upload
    </button>
  </div>
  <div class="row pb-2">
      <div class="row p-0">
        
        <div
          *ngFor="let data of watchlistData; trackBy: trackById"
          class="col-lg-4 col-md-6 col-sm-12 mb-3 "
        >
          <div class="card-wrap card-wrap--hover rounded pb-3 p-3" style="max-width: 100%;">
            <div class="ratio ratio-16x9">
              <youtube-player
                #player
                [videoId]="data.mediaLinks"
                class="w-100 h-100"
                (ready)="onPlayerReady()"
                (stateChange)="onStateChange($event)"
              >
              </youtube-player>
            </div>
            <div class="p-2" [routerLink]="['/view/watchNow/view', data.id]">
              <h2 class="title4-highlighted">{{ data.name }}</h2>
              <h3 class="title6-highlighted">{{ data.genre }}</h3>
              <h3 class="title6-dim">
                {{ data.description | trimText : 50 }}
              </h3>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
