<div class="pad-t container-lg">
  <div class="card-wrap rounded p-4 mb-4">
  <div *ngIf="isProjectAdmin">
    <div class="float-end ms-2 mt-2">
      <i class="fa fa-edit text-primary-rev cursor-hand" (click)="onProjectDetailsEdit()"></i>
    </div>
  </div>
  <div class="row pb-2 ">
    <div class="d-flex gap-lg-3 flex-wrap">
        <youtube-player #player [videoId]="watchNowData?.mediaLinks" 
          (ready)="onPlayerReady()" (stateChange)="onStateChange($event)">
        </youtube-player>
        <div class="col-lg-6 col-md-6 col-sm-12">
        <h2 class="title2-highlighted">{{watchNowData?.name}}</h2>
        <h2 class="title5-dim">{{watchNowData?.description}}</h2>
        <h2 class="title5-highlighted">{{watchNowData?.genre}}</h2>
        <h2 class="title5-highlighted">{{watchNowData?.language}}</h2>
        <h2 class="title6-dim">{{watchNowData?.linkRestrict}}</h2>
      </div>
    </div>
  </div>
  </div>
</div>




<div class="modal fade" id="watchNowEditForm" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content bg-app-primary">
      <div class="modal-body p-4">
        <form [formGroup]="watchNowEditForm">
          <div class="mb-3">
            <div class="text-white">name</div>
            <input type="text" class="input-field form-control" formControlName="name">
          </div>
          <div class="mb-3">
            <div class="text-white">description</div>
            <textarea class="input-field form-control" formControlName="description"></textarea>
          </div>

          <div class="mb-3">
            <div class="text-white">genre</div>
            <input type="text" class="input-field form-control" formControlName="genre">
          </div>

          <div class="mb-3">
            <div class="text-white">language</div>
            <input type="text" class="input-field form-control" formControlName="language">
          </div>
          <div class="col mb-2">
            <label class="title6-highlighted" for="mediaLinks"
              >Provide Youtube link:</label
            >
            <input
              type="text"
              class="input-field form-control"
              id="mediaLinks"
              formControlName="mediaLinks"
            />
          </div>

        <div class="col mb-2">
          <label class="title6-highlighted" for="linkRestrict">Piracy:</label>

          <select id="linkRestrict" formControlName="linkRestrict" class="input-field form-control">
            <option value="Public">Public</option>  
            <option value="Private">Private</option>
          </select>
        </div>

          <div class="mb-1 text-end">
            <div class="btn btn-grad-ff ms-2" *ngIf="watchNowEditForm.dirty" (click)="onEditSubmit()">
              <span>Save </span>
            </div>
            <div class="btn btn-secondary-outline ms-2" (click)="onModalCancel()">
              <span>Cancel </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


