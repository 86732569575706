<div class="container-lg text-white pad-t">
  <div class="card-wrap rounded p-4">
    <form [formGroup]="watchListForm">
      <div class="w-100 d-inline-block">
        <div class="float-start">
          <h2 class="title3-highlighted">Video Details</h2>
        </div>
        <div class="text-end">
          <button
            class="btn btn-grad-ff btn-md"
            (click)="onSubmit()"
            [disabled]="watchListForm.invalid"
          >
            Create
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5 col-lg-5 col-sm-6  mb-2">
          <div class="custom-upload">
            <div *ngIf="!videoId">
              <span class="upload-text">preview of Youtube video</span>
            </div>
            <div *ngIf="videoId" class="embed-container">
              <youtube-player
              [videoId]="videoId"
              [height]="315"
              [width]="560"
            ></youtube-player>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-10 col-md-12 col-sm-12">
          <div class="row">
            <div class="col mb-2">
              <label class="title6-highlighted" for="videoName"
                >Video Name:</label
              >
              <input
                type="text"
                class="input-field form-control"
                id="videoName"
                formControlName="name"
              />
            </div>
            <div class="col mb-2">
              <label class="title6-highlighted" for="description"
                >Description:</label
              >
              <input
                type="text"
                class="input-field form-control"
                id="description"
                formControlName="description"
              />
            </div>
          </div>
          <div class="row">
            <div class="col mb-2">
              <label class="title6-highlighted" for="genre">Genre:</label>
              <input
                type="text"
                class="input-field form-control"
                id="genre"
                formControlName="genre"
              />
            </div>
            <div class="col mb-2">
              <label class="title6-highlighted" for="language">Language:</label>
              <input
                type="email"
                class="input-field form-control"
                id="language"
                formControlName="language"
              />
            </div>
          </div>
          <div class="row">
            <div class="col mb-2">
              <label class="title6-highlighted" for="mediaLinks"
                >Provide Youtube link:</label
              >
              <input
                type="text"
                class="input-field form-control"
                id="mediaLinks"
                formControlName="mediaLinks"
              />
            </div>
            <div class="col mb-2">
              <label class="title6-highlighted" for="linkRestrict"
                >Piracy:</label
              >
              <select
                id="linkRestrict"
                formControlName="linkRestrict"
                class="input-field form-control"
              >
                <option value="Public">Public</option>
                <option value="Private">Private</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
