import { AbstractControl, ValidationErrors } from "@angular/forms";

export function phoneNumberValidation(value: string): boolean {
  return /^\d{10}$/.test(value);
}
export function passwordValidation(value: string): boolean {
  if (value.length < 8) {
    defaultRules["password"].errorMessage = 'Password must be at least 8 characters long.';
    return false;
  }

  const alphabeticCount = (value.match(/[a-zA-Z]/g) || []).length;
  if (alphabeticCount < 5) {
    defaultRules["password"].errorMessage = 'Password must contain at least 5 alphabetic characters.';
    return false;
  }

  if (!/\d/.test(value)) {
    defaultRules["password"].errorMessage = 'Password must contain at least one numeric character.';
    return false;
  }

  // If all checks pass
  return true;
}




export function emailValidation(value: string): boolean {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
}

export function notNullValidation(value: string): boolean {
  return value.trim().length > 0;
}

// Define types for validation rules and custom error messages
type ValidationRule = {
  validate: (value: string) => boolean;
  errorMessage: string;
};

type ValidationRules = {
  [key: string]: ValidationRule;
};


export const defaultRules: ValidationRules = {
  phoneNumber: {
    validate: phoneNumberValidation,
    errorMessage: 'Phone number must be exactly 10 digits.',
  },
  email: {
    validate: emailValidation,
    errorMessage: 'Invalid email format.',
  },
  textField: {
    validate: notNullValidation,
    errorMessage: 'Field cannot be empty.',
  },
  password: {
    validate: passwordValidation,
    errorMessage: 'Password must be exactly 8 characters long and contain at least one numeric character.',
  }
};


// Generic field validation function
export function validateFields(
  fieldData: Record<string, string>,
  rules: ValidationRules = defaultRules
): Record<string, string> {
  const errors: Record<string, string> = {};

  for (const [field, value] of Object.entries(fieldData)) {
    const rule = rules[field];
    if (rule && !rule.validate(value)) {
      errors[field] = rule.errorMessage;
      console.log(`Validation failed for "${field}": ${rule.errorMessage}`);
    }
  }

  return errors;
}



export function youtubeLinkValidator(control: AbstractControl): ValidationErrors | null {
  const url = control.value;

  if (!url) {
    return null; // No error if the field is empty (use `Validators.required` for mandatory fields)
  }

  // Regular expression for YouTube URLs
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[\w-]{11}$/;

  return youtubeRegex.test(url) ? null : { invalidYoutubeLink: true };
}